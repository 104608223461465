import { createContext } from '@bitmap/utils/utils/createContext';
import { IReview } from '@/src/interfaces/generated/contentful';
import { IFetchProductVariantSchema } from '@bitmap/contracts';

export type IProductSelectedOptions = {
  optionId: number;
  optionName: string;
  value: string | number | null;
}[];

export type IProductContext = {
  productVariant: IFetchProductVariantSchema;
  reviews: IReview[];
  selectedVariant: IFetchProductVariantSchema;
  options: IFetchProductVariantSchema['product']['options'];
};

export const [ProductContext, useProductContext] =
  createContext<IProductContext>();
