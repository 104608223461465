import { ReactNode, useEffect, useState } from 'react';
import { IFetchBundleSchema } from '@bitmap/contracts';
import { findSelectedSku } from '@/src/packages/components/contexts/product-form.context';
import { useBasket } from '@/src/packages/components/contexts/basket.context';
import { useDisclosure } from '@bitmap/utils/hooks/use-disclosure';
import { createContext } from '@bitmap/utils/utils/createContext';

//   const hasOptions =
//     props.bundle?.variants.filter((v) => v.product.options.length > 0) || [];

export const [BundleContextProvider, useBundleContext] = createContext<{
  selectedOptions: SelectedOptions;
  bundle: IFetchBundleSchema;
  isValid: boolean;
  handleAddToBag(): void;
  isModalOpen: boolean;
  handleOpenModal(): void;
  handleCloseModal(): void;
  setSelectedOptions(options: SelectedOptions): void;
}>();

type SelectedOptions = {
  optionId: number;
  productId: number;
  optionName: string;
  value: string | number | null;
}[];

const generateInitialValues = (variants: IFetchBundleSchema['variants']) => {
  const initialValue: SelectedOptions = [];

  variants.map((variant) => {
    variant.product.options.map((option) => {
      initialValue.push({
        productId: variant.product.id,
        optionId: option.id,
        optionName: option.name,
        value: null,
      });
    });
  });

  return initialValue;
};

export const BundleContext = ({
  children,
  bundle,
}: {
  children: ReactNode;
  bundle: IFetchBundleSchema;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>([]);

  const { addItem, isUpdating } = useBasket();

  useEffect(() => {
    setSelectedOptions(generateInitialValues(bundle.variants));
  }, [bundle]);

  const isValid = selectedOptions.every((option) => option.value !== null);

  const handleAddToBag = () => {
    if (isValid) {
      const skuIds: number[] = [];

      bundle.variants.map((variant) => {
        // Grab all options that relate to this product
        const options = selectedOptions.filter(
          (option) => option.productId === variant.product.id
        );

        const sku =
          options.length > 0
            ? findSelectedSku(options, variant.skus)
            : variant.skus[0];

        if (sku) skuIds.push(sku.id);
      });

      const payload = skuIds.map((skuId) => {
        return {
          skuId,
          quantity: 1,
        };
      });

      addItem(payload);
    } else {
      alert('missing options');
    }
  };

  const {
    isOpen: isModalOpen,
    onClose: handleCloseModal,
    onOpen: handleOpenModal,
  } = useDisclosure();

  useEffect(() => {
    if (!isUpdating) handleCloseModal();
  }, [isUpdating]);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedOptions(generateInitialValues(bundle.variants));
    }
  }, [isModalOpen]);

  return (
    <BundleContextProvider
      value={{
        selectedOptions,
        setSelectedOptions,
        isValid,
        bundle,
        handleAddToBag,
        handleCloseModal,
        handleOpenModal,
        isModalOpen,
      }}
    >
      {children}
    </BundleContextProvider>
  );
};
