import { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperCore from 'swiper';
import { useProductContext } from '../ProductContext';
import { CarouselPagination } from '@/src/packages/components/ui/CarouselDots';
import { Box } from '@/src/packages/components/ui/Box';
import { screens } from '@/src/packages/utils/generateImageSizes';
import clsx from 'clsx';
import Image from 'next/image';
import { CarouselArrow } from '@/src/packages/components/ui/CarouselArrow';
import {
  ZoomableImageProvider,
  ZoomableImageWrapper,
} from '@/src/packages/components/ui/ZoomableImage';
import { Asset } from 'contentful';

const ProductThumbnailsVertical = ({
  title,
  images,
  activeIndex,
  setActiveIndex,
}: {
  title: string;
  images: Asset[];
  activeIndex: number;
  setActiveIndex(index: number): void;
}) => {
  return (
    <Box className="flex w-12 flex-col gap-2 overflow-hidden lg:flex">
      {images.map((image, i) => {
        const src = image.fields.file.url;

        const isActive = i === activeIndex;

        return (
          <Box
            aspectRatio={{ initial: '1/1', lg: '1/1' }}
            position="relative"
            key={i}
          >
            <Image
              src={src}
              className={clsx('cursor-pointer border object-cover', {
                'border-transparent opacity-75': !isActive,
                'border-black': isActive,
              })}
              sizes={`48px`}
              fill
              alt={title}
              onClick={() => setActiveIndex(i)}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const ProductThumbnailsHorizontal = ({
  title,
  images,
  activeIndex,
  setActiveIndex,
}: {
  title: string;
  images: Asset[];
  activeIndex: number;
  setActiveIndex(index: number): void;
}) => {
  return (
    <Swiper className="relative flex-1 overflow-hidden" slidesPerView={3.1}>
      {images.map((image, i) => {
        const src = image.fields.file.url;

        const isActive = i === activeIndex;

        return (
          <SwiperSlide key={i}>
            <Box
              aspectRatio={{ initial: '1/1', lg: '1/1' }}
              position="relative"
              key={i}
            >
              <Image
                src={src}
                className={clsx('cursor-pointer object-cover', {
                  'opacity-50': !isActive,
                })}
                sizes={`48px`}
                fill
                alt={title}
                onClick={() => setActiveIndex(i)}
              />
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const ProductImageList = () => {
  const {
    productVariant: { title, images },
  } = useProductContext();

  return (
    <Box className="gap-gutter hidden flex-col lg:flex">
      {images.map((image, i) => {
        const src = image.fields.file.url;

        return (
          <Box className="relative aspect-square" key={i}>
            <Image
              src={src}
              fill
              alt={title}
              sizes="50vw"
              className="object-cover"
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const ProductImageCarousel = ({
  title,
  images,
}: {
  title: string;
  images: Asset[];
}) => {
  const swiperRef = useRef<SwiperCore>();

  const [activeIndex, setActiveIndex] = useState(0);

  const [total, setTotal] = useState(0);

  const [isEnd, setEnd] = useState(false);

  const [isBeginning, setBeginning] = useState(true);

  useEffect(() => {
    setActiveIndex(0);
  }, [images]);

  return (
    <ZoomableImageProvider images={images}>
      <Swiper
        loop
        className="relative flex-1 overflow-hidden"
        onInit={(swiper) => {
          swiperRef.current = swiper;
          setTotal(swiperRef?.current?.slides?.length);
        }}
        onRealIndexChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
        onActiveIndexChange={(swiper) => {
          setActiveIndex(swiper.realIndex);
        }}
        onSlideChange={(swiper) => {
          setBeginning(swiper.isBeginning);
          setEnd(swiper.isEnd);
        }}
        onResize={(swiper) => {
          setBeginning(swiper.isBeginning);
          setEnd(swiper.isEnd);
        }}
        onReachEnd={() => {
          setEnd(true);
        }}
        onReachBeginning={() => {
          setBeginning(true);
        }}
        slidesPerView={1}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {images.map((image, i) => {
          const src = image.fields.file.url;

          return (
            <SwiperSlide key={i}>
              <ZoomableImageWrapper>
                <Box className="relative aspect-square">
                  <Image
                    src={src}
                    className="object-cover"
                    sizes={`(max-width: ${screens.lg}) 100vw, 50vw`}
                    fill
                    alt={title}
                  />
                </Box>
              </ZoomableImageWrapper>
            </SwiperSlide>
          );
        })}

        {!isEnd && (
          <CarouselArrow
            onClick={() => swiperRef?.current?.slideNext()}
            direction="next"
          />
        )}

        {!isBeginning && (
          <CarouselArrow
            onClick={() => swiperRef?.current?.slidePrev()}
            direction="prev"
          />
        )}
      </Swiper>

      {total > 2 && (
        <CarouselPagination
          className="absolute bottom-8 left-1/2 z-10 -translate-x-1/2"
          activeIndex={activeIndex}
          slideCount={total}
          setActiveIndex={(index) => swiperRef.current?.slideTo(index)}
        />
      )}
    </ZoomableImageProvider>
  );
};

export const ProductImage = () => {
  const {
    productVariant: { title, images },
  } = useProductContext();

  return (
    <>
      <Box className="-mx-gutter lg:hidden">
        <ProductImageCarousel title={title} images={images} />
      </Box>
      <ProductImageList />
    </>
  );
};
