import { IProductSelectedOptions } from '@/src/packages/components/pages/Product/ProductContext';
import { createContext } from '@bitmap/utils/utils/createContext';
import { ReactNode, useEffect, useState } from 'react';
import { IFetchProductVariantSchema } from '@bitmap/contracts';
import { ProductOption, ProductOptionValue } from '@prisma/client';

export type IProductFormContext = {
  selectedSku: IFetchProductVariantSchema['skus'][0] | null;
  handleSelectOption(option: ProductOption, value: ProductOptionValue): void;
  selectedOptions: IProductSelectedOptions;
  setSelectedSku(sku: IFetchProductVariantSchema['skus'][0]): void;
};

export const [ProductFormContextProvider, useProductForm] =
  createContext<IProductFormContext>();

export const findSelectedSku = (
  selectedOptions: IProductSelectedOptions,
  skus: IFetchProductVariantSchema['skus']
) => {
  if (selectedOptions.length === 0) {
    return null;
  }

  return skus.find((sku) => {
    return selectedOptions.every((selectedOption) => {
      return sku.options.some((option) => {
        return (
          option.id === selectedOption.value &&
          option.productOptionId === selectedOption.optionId
        );
      });
    });
  });
};

export const ProductFormContext = ({
  options,
  skus,
  children,
}: {
  skus: IFetchProductVariantSchema['skus'];
  options: IFetchProductVariantSchema['product']['options'];
  children: ReactNode;
}) => {
  const [selectedSku, setSelectedSku] = useState<
    IFetchProductVariantSchema['skus'][0] | null
  >(null);

  const [selectedOptions, setSelectedOptions] =
    useState<IProductSelectedOptions>([]);

  useEffect(() => {
    const initialValue: IProductSelectedOptions = [];

    options.map((item) => {
      initialValue.push({
        optionId: item.id,
        optionName: item.name,
        value: null,
      });
    });

    setSelectedOptions(initialValue);
  }, []);

  useEffect(() => {
    if (skus.length === 1) {
      setSelectedSku(skus[0]);
    } else {
      const sku = findSelectedSku(selectedOptions, skus);
      if (sku) setSelectedSku(sku);
    }
  }, [selectedOptions, skus]);

  const handleSelectOption = (
    option: ProductOption,
    value: ProductOptionValue
  ) => {
    setSelectedOptions(
      selectedOptions.map((o) =>
        o.optionId === option.id ? { ...o, value: value.id } : o
      )
    );
  };

  return (
    <ProductFormContextProvider
      value={{
        selectedSku,
        handleSelectOption,
        selectedOptions,
        setSelectedSku,
      }}
    >
      {children}
    </ProductFormContextProvider>
  );
};
