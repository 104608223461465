'use client';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box } from '@/src/packages/components/ui/Box';
import type SwiperCore from 'swiper';
import Image from 'next/image';
import clsx from 'clsx';
import { useDisclosure } from '@bitmap/utils/hooks/use-disclosure';
import { createContext } from '@bitmap/utils/utils/createContext';
import { useLockBodyScroll } from 'react-use';
import { Close } from '@carbon/icons-react';
import { Expand } from 'lucide-react';
import { Center } from '@/src/packages/components/ui/Center';
import { cn } from '@/src/packages/utils/cn';
import { CloseButton } from '@/src/packages/components/ui/CloseButton';

type IZoomableImageContext = {
  images: {
    fields: {
      title: string;
      description: string;
      file: {
        url: string;
      };
    };
  }[];
  isOpen: boolean;
  onOpen(): void;
  onClose(): void;
  handleSlideTo(index: number): void;
  activeIndex: number;
};

export const [ZoomableImageContext, useZoomableImage] =
  createContext<IZoomableImageContext>();

const Thumbs = () => {
  const { images, activeIndex, handleSlideTo } = useZoomableImage();

  return (
    <Box className="flex h-[100px] flex-shrink-0 overflow-scroll border-t bg-white">
      {images.map((image, i) => {
        const src = image.fields.file.url;

        const isActive = i === activeIndex;

        return (
          <Box
            position="relative"
            className="aspect-square h-[100px]"
            key={i}
            onClick={() => handleSlideTo(i)}
          >
            <Image
              src={src}
              className={clsx('w-full cursor-pointer object-cover', {
                'opacity-50': !isActive,
              })}
              sizes={`48px`}
              fill
              alt=""
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const ZoomableImageProvider = ({
  children,
  images,
}: {
  children: ReactNode;
  images: IZoomableImageContext['images'];
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const swiperRef = useRef<SwiperCore>();

  const [activeIndex, setActiveIndex] = useState(0);

  const [isEnd, setEnd] = useState(false);

  const [isBeginning, setBeginning] = useState(true);

  useLockBodyScroll(isOpen);

  useEffect(() => {
    setActiveIndex(0);
  }, [images]);

  const handleSlideTo = (slide: number) => {
    swiperRef.current?.slideTo(slide);
  };

  return (
    <ZoomableImageContext
      value={{ images, handleSlideTo, activeIndex, isOpen, onClose, onOpen }}
    >
      <Box className="group relative">
        <Box
          className="absolute right-5 top-5 z-10 hidden h-10 w-10 cursor-pointer rounded-full bg-white opacity-0 transition-opacity hover:bg-black hover:text-white group-hover:opacity-100 lg:block"
          onClick={onOpen}
        >
          <Center>
            <Expand size={16} />
          </Center>
        </Box>

        {children}
      </Box>
      {isOpen && (
        <Box className="fixed left-0 top-0 z-50 flex h-screen w-screen flex-col overflow-hidden bg-white">
          <CloseButton className="fixed right-2 top-2 z-20" onClick={onClose} />
          <Box className="space-y-4 overflow-scroll p-4">
            {images.map((image, i) => {
              return (
                <Box className="relative aspect-square flex-1" key={i}>
                  <Image
                    src={image.fields.file.url}
                    fill
                    alt=""
                    className="object-cover"
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </ZoomableImageContext>
  );
};

export const ZoomableImageWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const { onOpen } = useZoomableImage();

  return (
    <Box onClick={onOpen} className={cn('cursor-pointer', className)}>
      {children}
    </Box>
  );
};
