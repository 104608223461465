import { useBundleContext } from '@/src/packages/components/pages/Bundle/BundleContext';
import { Box } from '@/src/packages/components/ui/Box';
import { Breadcrumb } from '@/src/packages/components/ui/Breadcrumb';
import { Button } from '@/src/packages/components/ui/Button';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { useProductPrice } from '@/src/packages/hooks/use-product-price';
import { paths } from '@/src/packages/routes';
import { pathParams } from '@/src/packages/routes/pathParams.enum';
import { getRoute } from '@/src/packages/utils/routes';
import {
  IFetchProductPriceListByCountry,
  StockLevels,
} from '@bitmap/contracts';
import { ProductImageCarousel } from '@/src/packages/components/pages/Product/ProductImage';

export const BundlePrice = ({
  priceLists,
}: {
  priceLists: IFetchProductPriceListByCountry;
}) => {
  const prices = useProductPrice(priceLists);

  return (
    <Box className="inline-flex space-x-1 leading-none">
      <Box className="line-through" color="weak">
        {prices?.priceOriginalGrossFormatted}
      </Box>
      <Box>{prices?.priceGrossFormatted}</Box>
      <Box className="text-[#f56756]">
        Save {prices?.discountGrossFormatted}
      </Box>
    </Box>
  );
};

export const BundleHeader = () => {
  const { bundle, handleOpenModal } = useBundleContext();

  const { range } = bundle;

  return (
    <Box as="header">
      {range && (
        <Box className="flex h-14 items-center">
          <Breadcrumb
            items={[
              {
                label: range.name,
                path: getRoute({
                  route: paths.RANGES_SINGLE,
                  params: { [pathParams.RANGE_SINGLE]: range.slug },
                }),
              },
              {
                label: bundle.name,
              },
            ]}
          />
        </Box>
      )}
      <Box
        className="-mx-gutter grid grid-cols-12 lg:mx-0 lg:gap-0"
        backgroundColor="panel"
      >
        <Box className="col-span-12 lg:col-span-6">
          <ProductImageCarousel title={bundle.name} images={bundle.images} />
        </Box>

        <Box className="col-span-12 flex flex-col justify-center space-y-4 px-6 pb-16 pt-8 lg:col-span-6">
          <Box className="mx-auto max-w-lg space-y-5">
            <Box className="space-y-2">
              <Heading as="h1" size="display" fontFamily="serif">
                {bundle.name}
              </Heading>
              <BundlePrice priceLists={bundle.priceList} />
            </Box>
            <Paragraph>{bundle.description}</Paragraph>

            {bundle.stockLevel !== StockLevels.out_of_stock ? (
              <Button
                onClick={handleOpenModal}
                variant="solid"
                size="lg"
                isBlock
              >
                Select options
              </Button>
            ) : (
              <Button variant="solid" size="lg" disabled isBlock>
                Out of stock
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
