import { times } from 'lodash';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';
import { cn } from '@/src/packages/utils/cn';

type ICarouselPagination = IBoxProps & {
  activeIndex: number;
  slideCount: number;
  setActiveIndex(index: number): void;
};

export const CarouselPagination = (props: ICarouselPagination) => {
  const { activeIndex, slideCount, setActiveIndex, className, ...rest } = props;

  return (
    <Box className={cn('inline-flex gap-4', className)} {...rest}>
      {times(slideCount, (index) => {
        return (
          <Box
            key={index}
            onClick={() => setActiveIndex(index)}
            className={cn('h-2 w-2 cursor-pointer rounded-full bg-black', {
              'opacity-30': index !== activeIndex,
            })}
          />
        );
      })}
    </Box>
  );
};
