export const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

type screens = keyof typeof screens | 'initial';

export const generateImageSizes = (sizes: {
  [key in screens]?: string;
}) => {
  const result: string[] = [];

  Object.keys(sizes).map((key) => {
    const size = key === 'intial' ? 0 : screens[key as keyof typeof screens];

    const value = sizes[key as keyof typeof sizes];

    result.push(`(min-width: ${size}) ${value}`);
  });

  return result.join(', ');
};

export const convertToPx = (screens: { [key: string]: number }) => {
  const result: { [key: string]: string } = {};

  for (const key in screens) {
    result[key] = `${screens[key]}px`;
  }

  return result;
};

export const screensPx = convertToPx(screens);
