import classNames from 'classnames';
import { Box } from '@/src/packages/components/ui/Box';
import { CaretLeft, CaretRight } from '@carbon/icons-react';
import { Center } from '@/src/packages/components/ui/Center';

type ICarouselArrowProps = {
  onClick(): void;
  direction: 'next' | 'prev';
};

export const CarouselArrow = ({ onClick, direction }: ICarouselArrowProps) => {
  return (
    <Box
      className={classNames(
        'absolute top-1/2 z-[2] hidden h-10 w-10 -translate-y-1/2 transform cursor-pointer rounded-full bg-white opacity-0 transition-all hover:bg-black hover:text-white group-hover:opacity-100 lg:block',
        {
          'left-4': direction === 'prev',
          'right-4': direction === 'next',
        },
      )}
      onClick={onClick}
    >
      <Center>
        {direction === 'prev' ? (
          <CaretLeft size={20} />
        ) : (
          <CaretRight size={20} />
        )}
      </Center>
    </Box>
  );
};
